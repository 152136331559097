import { memo, useEffect, useMemo, useState } from 'react'
import { css } from '@emotion/react'
import last from 'lodash/last'
import dayAPI from '~/utils/dayAPI'
import { useTradeStore } from '~/modules/SDK/Trade/useTradeStore'
import { tradeAPI } from '~/modules/SDK/Trade/tradeAPI'
import { Dayjs } from 'dayjs'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import DateRangePicker from '~/components/DateRangePicker'

import { useVirtualExchangeCenterParametersState } from '~/modules/virtual-exchange-center/containers/useVirtualExchangeCenterParameters'
import { ChipItem } from '~/modules/virtual-exchange-center/components/ChipItem'
import { perfGetMaxDrawDown } from '~/modules/SDK/Perf/perfGetMaxDrawDown'
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents'
import { AuthTradeAPI } from '~/modules/SDK/Trade/AuthTradeAPI'
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts-new'
import { representationAsNTD } from '~/utils/representationAsNTD'

const AccPnlContent = memo(function AccPnlContent() {
  const {
    state: { isUserQualified },
  } = useVirtualExchangeCenterParametersState.useContainer()

  const currentSelectAccountId = useTradeStore(s => s.currentSelectAccountId)

  const [begin, setBegin] = useState<Dayjs>(dayAPI().add(-3, 'month'))
  const [end, setEnd] = useState<Dayjs>(dayAPI())
  const isStrategyCenter = fr_agents.is['futuresai@strategy_center']
  useEffect(() => {
    if (begin.isValid() && end.isValid()) {
      if (isStrategyCenter)
        AuthTradeAPI.getDailyProfit(begin.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'))
      else if (currentSelectAccountId)
        tradeAPI.getDailyProfit(
          isUserQualified ? false : true,
          begin.format('YYYY-MM-DD'),
          end.format('YYYY-MM-DD'),
        )
    }
  }, [currentSelectAccountId, isUserQualified, begin, end])

  const dailyProfit = useTradeStore(state => state.dailyProfit)
  const data = useMemo<Structure[]>(() => {
    return (
      dailyProfit.map(datum => ({
        datetime: dayAPI(datum.datetime).format('YYYY/MM/DD'),
        accProfit: datum.realizedPnL,
        dailyProfit: datum.dailyRealizedPnL,
      })) ?? []
    )
  }, [dailyProfit])

  const headerCss = css`
    ${flex.h.allCenter};
    & > * {
      margin-right: 16px !important;
      margin-bottom: 16px;
    }
  `

  return (
    <div
      css={css`
        padding: 16px;
      `}
    >
      <div css={flex.h.crossCenter}>
        <DateRangePicker
          containerCss={headerCss}
          start={begin}
          end={end}
          startChangeDelegate={(value: Dayjs | null) => {
            // 由於資料都是 start of day, 且用 dayjs.isBetween 判斷，這裡扣 1 秒
            if (value) setBegin(value.startOf('day'))
          }}
          endChangeDelegate={(value: Dayjs | null) => {
            if (value) setEnd(value.endOf('day'))
          }}
        />
        <ChipItem
          label={'最大回撤'}
          value={perfGetMaxDrawDown(data.map(datum => datum.dailyProfit))}
        />
        <ChipItem
          label={'淨損益'}
          value={last(data.map(p => p.accProfit))}
        />
      </div>
      <div
        css={css`
          width: 720px;
          height: 400px;
        `}
      >
        <AccPlChart data={data} />
      </div>
    </div>
  )
})

type Structure = { datetime: string; accProfit: number; dailyProfit: number }

export const AccPlChart = memo<ReactProps<{ data: Structure[] }>>(function AccPlChart({ data }) {
  const gradientOffset = () => {
    const dataMax = Math.max(...(data?.map(i => i.accProfit) ?? [0]))
    const dataMin = Math.min(...(data?.map(i => i.accProfit) ?? [0]))

    if (dataMax <= 0) return 0
    if (dataMin >= 0) return 1

    return dataMax / (dataMax - dataMin)
  }

  const off = gradientOffset()

  return (
    <ResponsiveContainer
      width='100%'
      height='100%'
    >
      <AreaChart data={data}>
        <XAxis dataKey='datetime' />
        <YAxis
          dataKey='accProfit'
          tickFormatter={value => value / 10000 + '萬'}
        />
        <Tooltip content={<CustomizedTooltip />} />
        <CartesianGrid
          vertical={false}
          strokeDasharray='2 2'
        />
        <defs>
          <linearGradient
            id='splitColor2'
            x1='0'
            y1='0'
            x2='0'
            y2='1'
          >
            <stop
              offset={off}
              stopColor='#cc2222'
              stopOpacity={1}
            />
            <stop
              offset={off}
              stopColor='#11aa11'
              stopOpacity={1}
            />
          </linearGradient>
        </defs>
        <Area
          type='linear'
          dataKey='accProfit'
          stroke='url(#splitColor2)'
          fillOpacity={0.3}
          fill='url(#splitColor2)'
        />
      </AreaChart>
    </ResponsiveContainer>
  )
})

const CustomizedTooltip = (data: { payload?: { payload: Structure }[] }) => {
  if (!data || (data && (!data.payload || data.payload.length < 1))) return null
  const datum = data.payload?.[0].payload
  return (
    <div
      css={css`
        background: #ffffff;
        padding: 0 8px;
        border-radius: 8px;
        border: #a0a0a0 1px solid;
      `}
    >
      <p>{datum?.datetime}</p>
      <p>累積損益： {representationAsNTD(datum?.accProfit ?? 0)}</p>
    </div>
  )
}

export default AccPnlContent
